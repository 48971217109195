'use client'

import { Button, Chip as MuiChip, ListItemLayout, LuneTheme } from '@lune-fe/lune-ui-lib'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image from 'next/image'
import { FC } from 'react'
import CountUp from 'react-countup'

import Divider from '@/components/Divider'
import FullScreenOnMobileWrapper from '@/components/FullScreenOnMobileWrapper'
import TwoColumnLayout, { ColumnWrapOrder } from '@/components/TwoColumnLayout'
import useDevice from '@/hooks/useDevice'
import usePayhawkNumCalcs from '@/hooks/usePayhawkNumCalcs'

const INTERVAL_SECONDS = 60

const Chip: FC<{ label: string }> = ({ label }) => {
    const { smScreen } = useDevice()
    const { palette } = LuneTheme
    return (
        <MuiChip
            sx={{
                backgroundColor: 'unset',
                color: palette.White,
                borderRadius: '20px',
                background: 'rgba(255, 255, 255, 0.07)',
                height: '28px',
                ...(smScreen
                    ? {
                          fontSize: '12px',
                      }
                    : {}),
            }}
            label={label}
        />
    )
}

// eslint-disable-next-line complexity
const PayhawkCaseStudyBox: FC<{}> = () => {
    const { smScreen, mdScreen } = useDevice()
    const { palette, spacing } = LuneTheme
    const counters = usePayhawkNumCalcs({ intervalSeconds: INTERVAL_SECONDS })

    return (
        <FullScreenOnMobileWrapper>
            <TwoColumnLayout
                columnWrapOrderOnMobile={ColumnWrapOrder.REVERSE}
                sx={{
                    backgroundColor: '#143230',
                    ...(smScreen
                        ? {
                              backgroundImage: `url('/images/payhawk-case-study/payhawk-case-study-box-mobile.png')`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              padding: `${spacing(60)} ${spacing(4)} ${spacing(10)} ${spacing(4)}`,
                          }
                        : {
                              borderRadius: '48px',
                              padding: `${spacing(10)}`,
                          }),
                }}
                alignItems="flex-start"
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: mdScreen ? 3 : 4,
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            gap: 1.5,
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Chip label={`Expense management`} />
                        <Chip label={`Emission calculations`} />
                        <Chip label={`API`} />
                    </Box>
                    <Text sx={{ color: palette.White }} variant={mdScreen ? 'h5' : 'h4'}>
                        {`200+ Payhawk clients adopt emission reporting feature in 6 months`}
                    </Text>
                    <Text sx={{ color: palette.White }} variant={mdScreen ? 'body2' : 'body1'}>
                        {`With Lune, we’ve aligned Payhawk’s offerings with sustainability. Not just because it’s given us a competitive edge, but because it’s the right thing to do for our planet.`}
                    </Text>
                    <ListItemLayout
                        sx={{
                            '.MuiListItemAvatar-root': {
                                maxWidth: '72px',
                                maxHeight: '72px',
                            },
                        }}
                        title={
                            <Text sx={{ color: palette.White }} variant={'h6'}>
                                Raquel Orejas
                            </Text>
                        }
                        subTitle={
                            <Text sx={{ color: palette.White }} variant={'body3'}>
                                {`Product Marketing Manager at Payhawk`}
                            </Text>
                        }
                        image={
                            <Image
                                src={'/images/payhawk-case-study/raquel.png'}
                                width={72}
                                height={72}
                                alt={`Raquel Orejas`}
                            />
                        }
                    />
                    <Divider sx={{ opacity: '0.1' }} />
                    <Box>
                        <Text variant={'caption'} sx={{ color: palette.White }}>
                            Adoption
                        </Text>
                        <Text sx={{ color: palette.White }} variant={mdScreen ? 'h6' : 'h5'}>
                            200+ customers
                        </Text>
                    </Box>
                    <Box>
                        <Text variant={'caption'} sx={{ color: palette.White }}>
                            Usage
                        </Text>
                        <Text sx={{ color: palette.White }} variant={mdScreen ? 'h6' : 'h5'}>
                            {counters ? (
                                <CountUp
                                    duration={INTERVAL_SECONDS}
                                    start={counters.prev}
                                    end={counters.current}
                                    separator={','}
                                    decimals={0}
                                    suffix={' calculations'}
                                />
                            ) : (
                                <>500,000 calculations</>
                            )}
                        </Text>
                    </Box>
                    {smScreen && (
                        <Button
                            sx={{ width: '100%' }}
                            light={true}
                            variant={'contained'}
                            href={`/customers/payhawk`}
                            leftIcon={<ArrowForwardOutlinedIcon />}
                        >
                            Read the case study
                        </Button>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: mdScreen ? 3 : 9,
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            height: 'auto',
                            position: 'relative',
                        }}
                    >
                        {smScreen ? (
                            <></>
                        ) : (
                            <Image
                                src={
                                    '/images/payhawk-case-study/payhawk-case-study-box-desktop.png'
                                }
                                width={480}
                                height={480}
                                alt={`Payhawk card`}
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                    width: '100%',
                                    borderRadius: '24px',
                                }}
                            />
                        )}
                    </Box>
                    {!smScreen && (
                        <Button
                            sx={{ width: '100%' }}
                            light={true}
                            variant={'contained'}
                            href={`/customers/payhawk`}
                            leftIcon={<ArrowForwardOutlinedIcon />}
                        >
                            Read the case study
                        </Button>
                    )}
                </Box>
            </TwoColumnLayout>
        </FullScreenOnMobileWrapper>
    )
}

export default PayhawkCaseStudyBox
