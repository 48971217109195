import Image from 'next/image'

import CustomerStoryWithPercentage from '@/components/CustomerStoryWithPercentage'

const FortoCustomerStoryWithPercentage = () => {
    return (
        <CustomerStoryWithPercentage
            backgroundImageSrc={'/images/customer-quotes/forto-stat-desktop.png'}
            mobileBackgroundImageSrc={'/images/customer-quotes/forto-stat-mobile.png'}
            logo={
                <Image
                    src={'/images/logos/forto.png'}
                    height={24}
                    width={98.12}
                    alt={'Forto customer story'}
                />
            }
            text={<>of Forto’s customers now compensate for their transport emissions</>}
            percentage={'70'}
        />
    )
}

export default FortoCustomerStoryWithPercentage
