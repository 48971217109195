'use client'

import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import { FC, ReactNode } from 'react'

import TwoColumnsWithBackgroundImage from '@/components/TwoColumnsWithBackgroundImage'
import useDevice from '@/hooks/useDevice'

const CustomerStoryWithPercentage: FC<{
    backgroundImageSrc: string
    mobileBackgroundImageSrc: string
    logo?: ReactNode
    text: ReactNode
    percentage: string
    light?: boolean
}> = ({ backgroundImageSrc, mobileBackgroundImageSrc, logo, text, percentage, light }) => {
    const { mdScreen, smScreen } = useDevice()
    const { palette } = LuneTheme

    return (
        <TwoColumnsWithBackgroundImage
            backgroundImage={smScreen ? mobileBackgroundImageSrc : backgroundImageSrc}
            mobileBackgroundImage={mobileBackgroundImageSrc}
            leftSide={
                <Box
                    sx={{
                        gap: mdScreen ? 3 : 4,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Text
                        sx={{ color: light ? `${palette.White}` : `${palette.Grey900}` }}
                        variant={'button'}
                    >
                        CUSTOMER STORY
                    </Text>
                    {logo}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            flexDirection: 'column',
                        }}
                    >
                        <Text
                            variant={'h3'}
                            sx={{
                                fontSize: mdScreen ? '64px' : '96px',
                                fontWeight: '500',
                                lineHeight: '115px',
                                color: light ? `${palette.White}` : `${palette.Grey900}`,
                            }}
                        >
                            {percentage}%
                        </Text>
                        <Text
                            variant={'h3'}
                            sx={{
                                fontSize: mdScreen ? '24px' : '36px',
                                fontWeight: '400',
                                lineHeight: '43.2px',
                                maxWidth: smScreen ? 'auto' : '435px',
                                color: light ? `${palette.White}` : `${palette.Grey900}`,
                            }}
                        >
                            {text}
                        </Text>
                    </Box>
                </Box>
            }
            reversedOnMobile={false}
        />
    )
}

export default CustomerStoryWithPercentage
