import moment from 'moment'

export const truncateStringWithoutCutWords = (str: string | undefined, maxLen: number) => {
    const separator = ` `
    if (str === undefined || str.length <= maxLen) return str
    const truncated = str.substr(0, str.lastIndexOf(separator, maxLen))
    return truncated.length < str.length ? `${truncated}...` : truncated
}

export const formatDate = (date: Date): string => {
    return moment(date).format('MMMM D, YYYY')
}

// Keep this list in sync with next.config.js.
const IMAGE_WIDTHS = [
    // The default imageSizes as of 2024-01-08
    16, 32, 48, 64, 96, 128, 256, 384,
    // Our configured deviceSizes as of 2024-01-08
    640, 750, 828, 1080, 1200, 1920, 2048, 3840, 1280, 1440, 1600,
].sort((a, b) => a - b)

/**
 * Returns a path to fetch an optimized version of an image.
 *
 * This is to prevent loading original, large (both in dimensions and in file weight) images.
 *
 * When Mext.js' Image is used this happens automatically behind the scenes but sometimes
 * we need to do this manually (for example when dealing with backgrounds where Image is not
 * used).
 *
 * Should be treated carefully, it's possible it breaks if Next.js' internals change or
 * something.
 *
 * @param rawUrl A URL or a path to an existing, original image.
 * @param width The desired image width in integer pixels.
 *
 * This is actual image pixels, after taking the display's DPI/devicePixelRatio into
 * consideration.
 *
 * The actual image size delivered can be different than requested, depending on the available
 * image sizes.
 *
 * @param quality The integer quality from 1 to 100 (the higher the better). We have
 * a sensible default.
 */
export function optimizedImagePath({
    rawUrl,
    width: requestedWidth,
    quality = 75,
}: {
    rawUrl: string
    width: number
    quality?: number
}): string {
    if (!Number.isInteger(requestedWidth)) {
        throw new Error(`${requestedWidth} is not an integer`)
    }
    if (!Number.isInteger(quality)) {
        throw new Error(`${quality} is not an integer`)
    }
    const firstGreaterWidth = IMAGE_WIDTHS.filter((w) => w >= requestedWidth).at(0)
    // SAFETY: We know we have at least one item there, this won't return undefined.
    const maxWidth = IMAGE_WIDTHS.at(-1)!
    const width = firstGreaterWidth ?? maxWidth

    const params = new URLSearchParams([
        ['url', rawUrl],
        ['w', width.toString()],
        ['q', quality.toString()],
    ])
    return `/_next/image?${params}`
}

/**
 * @param error An exception thrown when attempting to fetching something from Prismic.
 */
export function looksLikePrismicInvalidCacheError(error: unknown): boolean {
    return (
        error instanceof Error &&
        error.message.includes('Ref not found. Ensure you have the correct ref and try again')
    )
}

/**
 * @param key The string describing the cookie's name.
 * @param documentCookie The cookie string containing all cookies, formated as the browsers `document.cookie` variable.
 * If the documentCookie string is not formatted correctly, the behaviour is undefined.
 */
export function getCookie(key: string, documentCookie: string): string | undefined {
    const b = documentCookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
    return b?.pop() ?? undefined
}
