import { LuneTheme } from '@lune-fe/lune-ui-lib'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC } from 'react'

const Divider: FC<{ sx?: SxProps }> = ({ sx }) => {
    const { palette } = LuneTheme

    return (
        <Box
            sx={{
                width: '100%',
                height: '1px',
                backgroundColor: palette.Grey300,
                ...(sx ?? {}),
            }}
        />
    )
}

export default Divider
