'use client'

import Box from '@mui/material/Box'
import { SxProps } from '@mui/system'
import { FC, ReactNode } from 'react'

import useDevice from '@/hooks/useDevice'

const FullScreenOnMobileWrapper: FC<{
    sx?: SxProps
    children: ReactNode
}> = ({ sx, children }) => {
    const { smScreen, xsScreen } = useDevice()

    const MOBILE_HORIZONTAL_MARGIN = 24
    const TABLET_HORIZONTAL_MARGIN = 88

    return (
        <Box
            sx={{
                width: xsScreen
                    ? `calc(100% + ${MOBILE_HORIZONTAL_MARGIN * 2}px)`
                    : smScreen
                      ? `calc(100% + ${TABLET_HORIZONTAL_MARGIN * 2}px)`
                      : '100%',
                marginLeft: xsScreen
                    ? `-${MOBILE_HORIZONTAL_MARGIN}px !important`
                    : smScreen
                      ? `-${TABLET_HORIZONTAL_MARGIN}px !important`
                      : 0,
                marginRight: xsScreen
                    ? `-${MOBILE_HORIZONTAL_MARGIN}px !important`
                    : smScreen
                      ? `-${TABLET_HORIZONTAL_MARGIN}px !important`
                      : 0,
                ...sx,
            }}
        >
            {children}
        </Box>
    )
}

export default FullScreenOnMobileWrapper
