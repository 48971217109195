'use client'

import { Button } from '@lune-fe/lune-ui-lib'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { SxProps } from '@mui/system'
import { FC } from 'react'

const LuneButton: FC<{
    label: string
    href?: string
    onClick?: () => void
    sx?: SxProps
    target?: string
}> = ({ label, href, sx, onClick, target }) => {
    return (
        <Button
            variant={'text'}
            href={href}
            rightIcon={<ArrowForwardOutlinedIcon />}
            onClick={onClick}
            target={target}
            /* @ts-ignore: ... */
            rel={target === '_blank' ? 'noreferrer' : undefined}
            sx={{
                textTransform: 'none',
                pl: 0,
                pr: 0,
                '.MuiSvgIcon-root': {
                    transform: 'translateX(0px)',
                    transition: 'transform 400ms cubic-bezier(0.680, -0.550, 0.265, 1.550)',
                },
                '.MuiTouchRipple-root': {
                    display: 'none',
                },
                '&:focus': {
                    backgroundColor: 'transparent !important',
                },
                '&:hover': {
                    backgroundColor: 'transparent !important',
                    '.MuiSvgIcon-root': {
                        transform: 'translateX(4px)',
                    },
                },
                ...sx,
            }}
        >
            {label}
        </Button>
    )
}

export default LuneButton
