'use client'

import Box from '@mui/material/Box'
import Text from '@mui/material/Typography'
import Image from 'next/image'
import { FC, ReactNode } from 'react'

import FullScreenOnMobileWrapper from '@/components/FullScreenOnMobileWrapper'
import useDevice from '@/hooks/useDevice'

const BlueBox: FC<{
    text: ReactNode
    rightSide: ReactNode
}> = ({ text, rightSide }) => {
    const { smScreen, mdScreen, xsScreen } = useDevice()

    return (
        <FullScreenOnMobileWrapper>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    ...(smScreen
                        ? {
                              pt: 6,
                              pb: 6,
                              pl: xsScreen ? 3 : 6,
                              pr: xsScreen ? 3 : 6,
                              borderRadius: 'none',
                          }
                        : {
                              pt: 8,
                              pb: 8,
                              pl: 9,
                              pr: 9,
                              borderRadius: '32px',
                          }),
                    img: {
                        position: 'absolute',
                        top: 0,
                        width: 'auto',
                        height: 'auto',
                        zIndex: -1,
                        right: '-50%',
                        left: '-50%',
                        margin: 'auto',
                    },
                }}
            >
                <Image
                    src={'/images/blue-box/blue-box-gradient-desktop.webp'}
                    width={2400}
                    height={700}
                    alt={`${text}-image`}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: smScreen ? 'flex-start' : 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexDirection: smScreen ? 'column' : 'row',
                        gap: smScreen ? 4 : 6,
                    }}
                >
                    <Text
                        variant={mdScreen ? 'h5' : 'h4'}
                        sx={{
                            flex: '0 1 auto',
                        }}
                    >
                        {text}
                    </Text>
                    <Box
                        sx={{
                            flex: '0 1 auto',
                            width: smScreen ? '100%' : 'auto',
                        }}
                    >
                        {' '}
                        {rightSide}
                    </Box>
                </Box>
            </Box>
        </FullScreenOnMobileWrapper>
    )
}

export default BlueBox
